<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import { getScrollTop, scrollTo } from '@utils/scroll'

import HomeFood from "@components/HomeFood";

export default {
  components: { Layout, HomeFood },
  data() {
    return {
      selectSoup: 'A',
      selectMain: 'seafood',
      food: [
        {
          alias: 'A',
          position: 'right',
          meat: {
            image: require('@assets/images/home/food/meat.png'),
            image_mobile: require('@assets/images/home/food/meat_mobile.png'),
            title: '頂級肉盤',
            content: '完美油花 頂級霜降<br>感受肉香與脂味在齒頰慢慢化開<br>每一口都是奢侈享受。',
            offset: [0, 493],  // 整個區塊 [ marginTop, left ]
            offset2: -69, // 右邊區塊 left
          },
          seafood: {
            image: require('@assets/images/home/food/seafood.png'),
            image_mobile: require('@assets/images/home/food/seafood_mobile.png'),
            title: '海鮮盛盤',
            content: '上乘組合 海鮮三昧<br>激鮮海味，極上絕品的大大滿足',
            offset: [0, 580],
            offset2: -36,
          },
        },
        {
          alias: 'B',
          image: require('@assets/images/home/food/food_b.png'),
          image_mobile: require('@assets/images/home/food/food_mobile_b.png'),
          title: '日本大蔥',
          content: '來自日本的甘甜大蔥<br>水潤飽滿、香甜有勁，是美味湯頭的靈魂要角',
          position: 'left',
          offset: [-156, 676],
          offset2: -102,
          height: 914,
        },
        {
          alias: 'C',
          image: require('@assets/images/home/food/food_c.png'),
          image_mobile: require('@assets/images/home/food/food_mobile_c.png'),
          title: '栗子南瓜',
          content: '黃澄澄的黃金南瓜養生必備聖品<br>加人火鍋熬煮更能品嘴它的絕妙滋味',
          position: 'right',
          offset: [-350, 70],
          offset2: -33,
          height: 690,
        },
        {
          alias: 'D',
          image: require('@assets/images/home/food/food_d.png'),
          image_mobile: require('@assets/images/home/food/food_mobile_d.png'),
          title: '鮮脆高麗菜',
          content: '鍋物的最佳拍檔<br>鮮脆清甜<br>每一口甘甜清香回味無窮',
          position: 'left',
          offset: [-247, 863],
          offset2: -10,
          height: 690,
        },
        {
          alias: 'E',
          image: require('@assets/images/home/food/food_e.png'),
          image_mobile: require('@assets/images/home/food/food_mobile_e.png'),
          title: '日本甜玉米',
          content: '金黃亮澤 顆顆飽滿<br>不可思議的甜度與香氣<br>驚豔完勝',
          position: 'right',
          offset: [-376, 126],
          offset2: -110,
          height: 845,
        },
        {
          alias: 'F',
          image: require('@assets/images/home/food/food_f.png'),
          image_mobile: require('@assets/images/home/food/food_mobile_f.png'),
          title: '日本起司竹輪',
          content: '紮實彈牙的口感<br>一口咬下濃郁起司瞬間滿溢<br>每口都是欲罷不能的大滿足',
          position: 'left',
          offset: [-287, 712],
          offset2: 28,
          height: 736,
        },
      ],
      sub_food: [
        // 蔥
        {
          alias: 'A',
          image: require('@assets/images/home/food/sub_food_a.png'),
          offset: [0, 1340] // [ top, left ]
        },
        // 辣椒
        {
          alias: 'B',
          image: require('@assets/images/home/food/sub_food_b.png'),
          offset: [176, 158]
        },
        // 青椒
        {
          alias: 'C',
          image: require('@assets/images/home/food/sub_food_c.png'),
          offset: [748, 1555]
        },
        // 洋蔥
        {
          alias: 'D',
          image: require('@assets/images/home/food/sub_food_d.png'),
          offset: [848, 0]
        },
        // 筷子
        {
          alias: 'E',
          image: require('@assets/images/home/food/sub_food_e.png'),
          offset: [1559, 1481] // 1619
        },
        // 蘿蔔
        {
          alias: 'F',
          image: require('@assets/images/home/food/sub_food_f.png'),
          offset: [1994, 0]
        },
        // 醬油
        {
          alias: 'G',
          image: require('@assets/images/home/food/sub_food_g.png'),
          offset: [2438, 1550]
        },
        // ？？
        {
          alias: 'H',
          image: require('@assets/images/home/food/sub_food_h.png'),
          offset: [2908, 218]
        },
        {
          alias: 'I',
          image: require('@assets/images/home/food/sub_food_i.png'),
          offset: [3012, 217]
        },
      ],
      window_w: 0,
    }
  },
  computed: {
    ...setComputed,
    mainFoodTranformStyle() {
      const originWidth = 1920;
      const windthWidth = this.window_w;

      if(this.window_w > 991) {
        let scale = 1 / (originWidth / windthWidth);

        return {
          transform: `scale(${scale })`,
          '-webkit-transform': `scale(${scale })`,
        }
      }else {
        return {}
      }
    },
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    onSelectSoup(type) {
      this.scrollTo(this.$style.sectionMain, 0, 'linear');

      this.selectSoup = type;
    },
    onSelectMain(type) {
      this.scrollTo(this.$style.sectionFood, 0, 'linear');

      this.selectMain = type;
    },
    getWindowWidth(){
      this.window_w = document.body.clientWidth
    },
    getScrollTop,
    scrollTo
  },

}
</script>
<template>
  <Layout>
    <section :class="$style.sectionBanner" class="section" data-section-logo-color="#fff">
      <img :class="$style.text" src="@assets/images/home/banner_text.svg">
      <div :class="$style.scrollTopBottom"
           class="justify-content-center">
        <div :class="$style.mouseBox">
          <div :class="$style.mouse"></div>
          <div :class="$style.mouseText">滑動體驗</div>
        </div>
      </div>
    </section>
    <section :class="$style.sectionSoup" class="section" data-section-logo-color="#fff">
      <div class="row h-100">
        <div @click="onSelectSoup('A')" class="col" :class="[$style.selectBox, $style.leftBox]">
          <img :class="$style.text" src="@assets/images/home/soup_text_1.svg">
        </div>
        <div @click="onSelectSoup('B')" class="col" :class="[$style.selectBox, $style.rightBox]">
          <img :class="$style.text" src="@assets/images/home/soup_text_2.svg">
        </div>
      </div>
    </section>
    <section :class="$style.sectionMain" class="section" data-section-logo-color="#fff">
      <div class="container-main h-100">
        <div class="row h-100">
          <div v-if="selectSoup !== 'B'" class="col" :class="[$style.selectBox, $style.leftBox]">
            <div :class="$style.selectBoxInner" class="d-flex flex-column justify-content-center" :style="mainFoodTranformStyle">
              <div @click="onSelectMain('seafood')" :class="$style.foodBox">
                <img :class="[$style.food, $style.seafood]" src="@assets/images/home/main_food_1.png">
              </div>
              <img :class="$style.title" src="@assets/images/home/main_text_1.svg">
              <div :class="$style.content">
                昆布鍋不可或缺的<br class="d-block d-lg-none">上乘組合<br class="d-lg-block d-none">極鮮大滿足
              </div>
            </div>
          </div>
          <div class="col" :class="[$style.selectBox, $style.rightBox]">
            <div :class="$style.selectBoxInner" class="d-flex flex-column justify-content-center" :style="mainFoodTranformStyle">
              <div @click="onSelectMain('meat')" :class="$style.foodBox">
                <img :class="[$style.food, $style.meat]" src="@assets/images/home/main_food_2.png">
              </div>
              <img :class="$style.title" src="@assets/images/home/main_text_2.svg">
              <div :class="$style.content">
                壽喜燒的絕配
                <br>每一口都是好滋味
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.shadow"></div>
    </section>
    <section :class="$style.sectionFood" class="section" data-section-logo-color="#000">
      <HomeFood :select-main="selectMain" :food="food" :sub-food="sub_food"/>
    </section>
    <section :class="$style.sectionEnd" class="section" data-section-logo-color="#fff">
      <div :class="$style.contentBox">
        <div :class="$style.title">
          一人想
          <br>一人饗
          <br>一人享
        </div>
        <div :class="$style.content">
          <p>
            橘色涮涮屋自2001年創立以來深受顧客喜愛，且已成為頂級涮涮鍋的代名詞。以橘色多年的經驗，於2017年正式推出新品牌「Extension 1 by 橘色」。維持橘色涮涮屋的高品質，創造出不一樣的新風貌－頂級單人鍋物。
          </p>
          <p>
            希望帶給每位蒞臨 Extension 1 by 橘色用餐的顧客，精緻且回味無窮的頂級饗宴，一個人也能吃的精彩、獨享美味。
          </p>
        </div>
        <div class="text-center text-lg-left">
          <router-link :class="$style.btnBooking" :to="{ name: 'booking' }">即刻訂位</router-link>
        </div>
      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  $section-height: 100vh;
  .sectionBanner {
    background-image: url(@assets/images/home/banner.jpg);
    position: relative;
    z-index: 0;
    height: $section-height;
    background-position: top;
    background-size: cover;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.15;
      z-index: -1;
    }
    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90vw;
    }
  }
  .sectionSoup {
    height: $section-height;
    .selectBox {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .text {
        transition: opacity 0.3s;
        opacity: 0;
      }
      &:before {
        transition: opacity 0.3s;
        opacity: 0;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #000;
        z-index: -1;
        @include touch {
          opacity: 0.75;
        }
      }
      &:hover {
        .text {
          opacity: 1;
        }
        &:before {
          opacity: 0.75;
        }
      }
      .text {
        @include touch {
          opacity: 1;
        }
      }
      &.leftBox {
        background-image: url(@assets/images/home/soup_bg_1.jpg);
        background-position: right;
        background-size: cover;
        position: relative;
        z-index: 0;
        .text {
          height: 62.3vh;
        }
        @include touch {
          .text {
            height: auto;
            width: 83px;
          }
        }
      }
      &.rightBox {
        background-image: url(@assets/images/home/soup_bg_2.jpg);
        background-position: left;
        background-size: cover;
        position: relative;
        z-index: 0;
        .text {
          height: 62.3vh;
        }
        @include touch {
          .text {
            height: auto;
            width: 94px;
          }
        }
      }
    }

  }
  .sectionMain {
    height: $section-height;
    background-image: url(@assets/images/home/main_bg.jpg);
    position: relative;
    z-index: 1;
    background-size: cover;
    &:before {
      transition: opacity 0.3s;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0.65;
      background: #000;
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 431px;
      z-index: -1;
      background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0.44) 18%, rgba(0, 0, 0, 0));
    }
    .shadow {
      transition: opacity 0.3s;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      opacity: 0.65;
      background-image: url(@assets/images/home/main_bg_text.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 104vw;
      z-index: -1;
      @include touch {
        background-size: 88vw;
        background-image: url(@assets/images/home/main_bg_text_mobile.svg);
      }
    }
    .selectBox {
      display: flex;
      align-items: center;
      justify-content: center;
      @include touch {
        width: 50%;
      }

      &.leftBox {
        .selectBoxInner {
          transform-origin: right;
        }
      }
      &.rightBox {
        .selectBoxInner {
          transform-origin: left;
        }
        .foodBox {
          .food {
            padding-top: 50px;
          }
        }
      }
      .foodBox {
        margin-bottom: 4.6296vh;
        height: 426px;
        width: 474px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        cursor: pointer;
        @include touch {
          height: 214px;
          width: 202px;
          margin-bottom: 30px;
        }
        .food {
          max-width: 100%;
          &.meat {
            @include touch {
              max-width: 110%;
            }
          }
        }
        &:before {
          content: '';
          width: 336px;
          height: 336px;
          background: #e85400;
          opacity: 0;
          position: absolute;
          border-radius: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          @include touch {
            display: none;
          }
        }
        &:hover {
          &:before {
            opacity: 0.75
          }
        }
      }
      .title {
        margin-bottom: 40px;
        @include touch {
          margin: 0 auto 24px;
          width: 83px;
        }
      }
      .content {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.63;
        letter-spacing: 3.2px;
        color: #f2f2f2;
        text-align: center;
        @include touch {
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 2.6px;

        }
      }

    }
  }
  .sectionFood {
    background-image: url(@assets/images/home/food_bg.jpg);
    overflow: hidden;
  }
  .sectionEnd {
    background-image: url(@assets/images/home/end_bg.jpg);
    color: #fff;
    position: relative;
    z-index: 1;
    height: calc(100vh - 97px);
    background-size: cover;
    @include touch {
      background-image: url(@assets/images/home/end_bg_mobile.jpg);
      padding: 59px 0 50px;
      height: auto;
    }
    &:before {
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 1083px;
      opacity: 0.6;
      background-image: linear-gradient(to right, #000000, rgba(0, 0, 0, 0));
      z-index: -1;
    }
    .contentBox {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 4.4vh;
      @include touch {
        margin-top: 0;
        position: static;
        transform: unset;
      }
      .title {
        font-size: 48px;
        font-weight: 600;
        line-height: 1.88;
        letter-spacing: 2.88px;
        margin-bottom: 6.5741vh;
        @include touch {
          text-align: center;
          font-size: 36px;
          line-height: 80px;
          letter-spacing: 2.16px;
          margin-bottom: 50px;
        }
      }
      .content {
        font-size: 14px;
        font-weight: 500;
        width: 600px;
        line-height: 1.79;
        letter-spacing: 2.8px;
        color: #f9f8f8;
        margin-bottom: 6.94445vh;
        @include touch {
          width: 100vw;
          padding: 0 20px;
          line-height: 24px;
          margin-bottom: 41px;
        }
      }
      .btnBooking {
        cursor: pointer;
        appearance: none;
        border: 1px solid #f9f8f8;
        font-size: 14px;
        letter-spacing: 2.8px;
        text-align: center;
        color: #f9f8f8;
        padding: 7px 34px;
        line-height: 28px;
        font-weight: 500;
        &:hover {
          text-decoration: none;
          background: #f9f8f8;
          color: #000;
        }
      }
    }
  }
  .scrollTopBottom {
    position: absolute;
    display: flex;
    width: 100vw;
    bottom: 45px;
    left: 0;
    .mouseBox {
      .mouse, .mouseText {
        opacity: .7
      }
      &:after {
        content: "";
        width: 1px;
        left: 50%;
        bottom: -45px;
        height: 30px;
        background: #fff;
        position: absolute;
        opacity: .7;
      }
    }
    .mouse {
      border-radius: 15.3px;
      width: 26px;
      height: 36px;
      border: 1px solid #fff;
      margin: 0 auto 16px auto;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 5px;
        height: 5px;
        border-radius: 100%;
        animation: scroll-down 2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
    .mouseText {
      color: white;
      letter-spacing: 1.8px;
      text-indent: 0.2rem;
      font-size: 12px;
      font-weight: 500;
    }

  }
  @keyframes scroll-down {
    0% {
      transform: translate(-50%, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
      background: #eb5e00;
    }
    80% {
      transform: translate(-50%, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
      background: white;
    }
  }
</style>
